<template>
  <section class="follow-us">
    <div class="container">
      <div class="follow-us__content">
        <div class="follow-us__text-content">
          <h4
            v-html="followUsData.heading"
            class="follow-us__heading"
            :class="followUsData.headingStyle === 'large' ? 'follow-us__heading--large' : ''"
          />
          <p class="follow-us__text" >{{ followUsData.text }}</p>

          <FeedbackForm :btnText="followUsData.buttonText" />
        </div>

        <div class="follow-us__img-content">
          <img class="follow-us__img" :src="followUsData.imageUrl">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import FeedbackForm from '@/components/FeedbackForm'

export default {
  name: 'FollowUs',
  components: {
    FeedbackForm
  },
  props: {
    followUsData: {
      type: Object,
      default: () => ({})
    }
  },
}
</script>