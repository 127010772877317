<template>
  <section class="user-advantages" id="userAdvantages">
    <div class="container">
      <h4 class="user-advantages__title section-title">Переваги</h4>

      <ul class="user-advantages__list">
        <li class="user-advantages__item" v-for="(item, index) in userAdvantagesData" :key="index">
          <h6 class="user-advantages__item-title">{{ item.title }}</h6>
          <p class="user-advantages__item-text">{{ item.text }}</p>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: 'UsersAdvantages',
  data: () => ({
    userAdvantagesData: [
      {
        title: 'Мобільність',
        text: 'Ви маєте змогу входу до персонального кабінету NTZ digital з будь-якого пристрою, ' +
          'що підтримує широкий формат екрану'
      },
      {
        title: 'Доступність',
        text: 'Внутрішня складова системи розроблена спеціально для сдачі онлайн тестів навіть недосвідченими' +
          ' користувачами систем'
      },
      {
        title: 'Компʼютерні обчислення',
        text: 'Результати тесту видаються автоматично – система направлена на перевірку глибоких знать курсів'
      },
      {
        title: 'Повторне тестування',
        text: 'Якщо за якихось причин вам не вдалося скласти іспит з першого разу, вам буде назначено іспит знову'
      },
      {
        title: 'Відповідальність',
        text: 'Ми працюємо над якістю нашого продукту, тому дослухаємося до усіх недоліків, що можуть виникати' +
          ' при тестуванні'
      },
      {
        title: 'Підтримка',
        text: 'Якщо ви маєте будь-які питання по роботі з системою ми готові допомогти вам.' +
          ' Звертайтесь до нашої служби підтримки'
      },
    ]
  })
}
</script>