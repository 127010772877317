<template>
  <section class="facts" id="facts">
    <div class="container">
      <div class="facts__content-wrapper">
        <h4 class="facts__title">{{ facts.title }}</h4>

        <p class="facts__text">{{ facts.text }}</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Facts',
  props: {
    facts: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>