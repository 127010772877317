<template>
  <section class="questions" id="questions">
    <div class="container">
      <h4 class="questions__title">Питання та відповіді</h4>

      <ul class="questions__list">
        <li
          v-for="item in questions"
          :key="item.id"
          class="questions__item"
        >
          <p class="questions__question">{{ item.question }}</p>
          <p class="questions__answer">{{ item.answer }}</p>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: 'QuestionsAndAnswers',
  props: {
    questions: {
      type: Array,
      default: () => ([{}])
    }
  }
}
</script>