<template>
  <div class="main">
    <vue-headful title="Ntz digital" />
    <FollowUs :followUsData="followUsData" />
    <UsersAdvantages />
    <HowIt :howItData="howItUserData" bullets="numeric" />
    <Facts :facts="factsAboutUs" />
    <QuestionsAndAnswers :questions="questionsAndAnswers" />
    <HaveQuestions />
  </div>
</template>

<script>
import FollowUs from '@/components/Sections/FollowUs'
import UsersAdvantages from '@/components/Sections/UsersAdvantages'
import HowIt from '@/components/Sections/HowIt'
import Facts from '@/components/Sections/Facts';
import QuestionsAndAnswers from '@/components/Sections/QuestionsAndAnswers';
import HaveQuestions from '@/components/Sections/HaveQuestions';

export default {
  name: 'UsersMain',
  components: {
    HaveQuestions,
    QuestionsAndAnswers,
    Facts,
    HowIt,
    UsersAdvantages,
    FollowUs
  },
  data: () => ({
    followUsData: {
      heading: '<strong>NTZ digital</strong> – надійний помічник в он-лайн тестуванні',
      headingStyle: 'large',
      text: 'Нова система для здачі екзаменів по курсам НТЗ у моряків.',
      buttonText: 'Надіслати',
      imageUrl: require('@/assets/ForUsersPage/users-follow-us.svg')
    },
    howItUserData: [
      {
        isOnRight: false,
        heading: 'Постановка екзамену',
        text: 'Після закінчення курсу у тренажерному закладі секретар НТЗ назначить екзамен і ви побачите його ' +
          'у вашому персональному кабінеті'
      },
      {
        isOnRight: true,
        heading: 'Тестування',
        text: 'Кожен курс пропонує необхідні для складення питання. Для відповіді на кожне з них ' +
          'ви маєте по одній хвилині.'
      },
      {
        isOnRight: false,
        heading: 'Миттєвий результат',
        text: 'Після складання іспиту система вираховує правильні та неправильні відповіді. Ви побачите ' +
          'результат одразу після проходження запитань'
      },
    ],
    factsAboutUs: {
      title: 'Деякі факти про нас',
      text:
        'Цього року NTZ digital має інтенсивний план розвитку системи. Окрім виходного контролю для різних НТЗ ' +
        'та роботи з інтеграціями в наших планах зробити дещо дійсно корисне учням – ' +
        'перелік питань для підготовки до іспиту.\n' +
        '\n' +
        'Ми розуміємо, що до компʼютерного тестування було б добре готуватися не тільки офлайн, а і віддаленно,\n' +
        '\n' +
        'тому кожен НТЗ, що працює з digital матиме змогу навчати дистанційно!\n' +
        '\n' +
        'Відеокурси, тестові запитання, добре викладений матеріал і багато іншого. Вже скоро..'
    },
    questionsAndAnswers: [
      {
        id: 1,
        question: 'Хто може назначати мені іспити?',
        answer: 'Іспити НТЗ можуть назначити тільки спеціальні співробітники того навчального закладу, до якого ' +
          'ви звертались для навчання. При успішному проходженні екзамена ваш тренувальний заклад підпише відповідний ' +
          'сертифікат про проходження вами курсу.'
      },
      {
        id: 2,
        question: 'Як дізнатися про дату екзамену?',
        answer: 'У персональному кабінеті NTZ digital вам буде доступна дата проведення та посилання на екзамен'
      },
      {
        id: 3,
        question: 'Скільки часу дається на складення тесту?',
        answer: 'Це залежить від кількості питань до курсу. На вирішення одного питання ви маєте одну хвилину. ' +
          'Питань в тесті може бути до 60 включно.'
      },
    ]
  })
}
</script>

<style lang="scss">

</style>