<template>
  <form class="form">
    <input class="form__input" type="email" placeholder="email@gmail.com">
    <button class="form__btn" type="submit">{{ btnText }}</button>
  </form>
</template>

<script>
export default {
  name: 'FeedbackForm',
  props: {
    btnText: String
  }
}
</script>