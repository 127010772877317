<template>
  <section class="have-questions">
    <div class="container">
      <h4 class="have-questions__heading">Залишились питання?</h4>
      <span class="have-questions__subtitle">
        Напишіть нам на поштову скриньку і ми відповімо протягом робочого дня
      </span>

      <form class="have-questions__form">
        <input
          class="have-questions__input"
          type="email"
          placeholder="Поштова скринька"
          name="email"
          id="userEmail"
        >

        <input
          class="have-questions__input"
          type="text"
          placeholder="Ваше запитання"
          name="question"
          id="userQuestion"
        >

        <button
          class="have-questions__btn"
          type="submit"
        >
          Надіслати
        </button>
      </form>

      <p class="have-questions__info-text">
        Вартість здачі одного екзамену з курсу <a class="have-questions__info-link" href="https://ntz.digital/">
        ntz.digital</a> складає від 300 грн за
        один курс.
        Відповідно до кількості курсів, що плануються до сдачі, ціна кожного курсу не змінюється
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'HaveQuestions',
}
</script>