<template>
  <div class="main">
    <vue-headful title="Ntz digital business" />
    <FollowUs :followUsData="followUsData" />
    <HowIt :howItData="howItBizData" bullets="circle" />
    <WhyWe />
    <Advantages />
    <TryNow />
  </div>
</template>

<script>
import FollowUs from '@/components/Sections/FollowUs'
import HowIt from '@/components/Sections/HowIt'
import WhyWe from '@/components/Sections/WhyWe'
import Advantages from '@/components/Sections/Advantages'
import TryNow from '@/components/Sections/TryNow'

export default {
  name: 'BusinessMain',
  components: {
    TryNow,
    Advantages,
    WhyWe,
    HowIt,
    FollowUs
  },
  data: () => ({
    followUsData: {
      heading: 'Заповни заявку та зроби свій НТЗ – <strong>діджитал!</strong>',
      text: 'Новий інструмент менеджменту допомагає коммунікації, управлінню часом та працює он-лайн.',
      buttonText: 'Напишіть мені',
      imageUrl: require('@/assets/follow-us-img.svg')
    },
    howItBizData: [
      {
        isOnRight: false,
        heading: 'Створення заявки',
        text: 'Вибір необхідного <strong>напряму підготовки</strong>\n' +
          'та створення спеціальної заявки для проходження екзамену'
      },
      {
        isOnRight: true,
        heading: 'Назначення іспиту',
        text: '<strong>Офлайн:</strong> сворення додатку до свідотства за даними після здачі іспиту у зазначеному місці\n' +
          '<strong>Онлайн:</strong> планування іспиту та повідомлення з посиланням на тест'
      },
      {
        isOnRight: false,
        heading: 'Підведення підсумків',
        text: 'Якщо <strong>тест сданий успішно</strong> - додаток до свідотства фахівця очікує підпису.\n' +
          'Після підписання формується сертифікат з датою проходження'
      },
      {
        isOnRight: true,
        heading: 'Несданий іспит?',
        text: 'Не проблема! Заявка вже сформована, тому для повторної сдачі потрібно лише назначити іспит.\n' +
          'Ніяких обмежень'
      },
    ]
  })
}
</script>