<template>
  <section class="why-we" id="whyWe">
    <div class="why-we__content-wrapper">
      <div class="container">
        <h4 class="why-we__heading">Чому ми</h4>

        <ul class="why-we__list">
          <li class="why-we__item" v-for="(item, index) in list" :key="index">
            <img class="why-we__item-img" :src="item.img" :alt="item.title">
            <h5 class="why-we__item-heading">{{item.title}}</h5>
            <p class="why-we__item-text">{{item.text}}</p>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'WhyWe',
  data: () => ({
    list: [
      {
        img: require("@/assets/why-we-01.png"),
        title: "Документи",
        text: "Працюючи з NTZ digital ви маєте змогу генерувати документи свого закладу прямо у персональному кабінеті онлайн."
      },
      {
        img: require("@/assets/why-we-02.png"),
        title: "Заявки",
        text: "У системі ведеться запис усіх заявок на навчання у тренажерному центрі, тому ви не пропустите жодного клієнта."
      },
      {
        img: require("@/assets/why-we-03.png"),
        title: "Безпека",
        text: "Усі дані вашого облікового запису компанії цілком безпечно розміщені та доступ до них відкривається лише співробітникам."
      },
      {
        img: require("@/assets/why-we-04.png"),
        title: "ЕЦП",
        text: "Ми працюємо у форматі paperless, тому усі документи у системі підписуються за допомогою електронного цифрового підпису."
      },
      {
        img: require("@/assets/why-we-05.png"),
        title: "Без обмежень",
        text: "NTZ digital дає змогу працювати з тою кількістю документів, з якою вам потрібно без жодних лімітів."
      },
      {
        img: require("@/assets/why-we-06.png"),
        title: "Звітність",
        text: "Ми знаємо як важливо працювати зі звітами, тому зробили їх зручними для роботи. Нам важливо вам догодити!"
      },
    ]
  })
}
</script>