<template>
  <header class="header">
    <div class="container">
      <Navigation />
    </div>
  </header>
</template>

<script>
import Navigation from '@/components/Navigation'
export default {
  name: 'Header',
  components: {Navigation}
}
</script>