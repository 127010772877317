<template>
  <BusinessMain v-if="isBizDomain" />
  <UsersMain v-else />
</template>

<script>
import BusinessMain from '@/components/Layouts/BusinessMain'
import UsersMain from '@/components/Layouts/UsersMain'

export default {
  name: 'Home',
  components: {
    UsersMain,
    BusinessMain
  },
  data: () => ({
    url: document.location.href,
  }),
  computed: {
    isBizDomain () {
      return !!this.url.match(/biz/)
    }
  }
}
</script>
