<template>
  <nav v-if="isBizDomain" class="header__navigation">
    <div class="header__logo-wrapper">
      <a href="/" class="header__logo-link">
        <img src="../assets/logo.png" alt="NTZ" class="header__logo">
      </a>
    </div>

    <ul
      class="header__navigation-list"
      :class="isActiveMenu ? 'header__navigation-list--active' : ''"
    >
      <li class="header__navigation-item">
        <router-link class="header__navigation-link" :to="{ hash: '#whyWe', path: '/' }">Чому ми</router-link>
      </li>
      <li class="header__navigation-item">
        <router-link class="header__navigation-link" :to="{ hash: '#advantages', path: '/' }">Переваги</router-link>
      </li>
      <li class="header__navigation-item">
        <router-link class="header__navigation-link" :to="{ hash: '#tryNow', path: '/' }">Розпочати</router-link>
      </li>
      <li class="header__navigation-item">
        <router-link class="header__navigation-link" :to="{ hash: '#contacts' }">Контакти</router-link>
      </li>
      <li class="header__navigation-item">
        <router-link class="header__navigation-link" :to="{ name: 'registration-ntz' }">Зареєструвати НТЗ</router-link>
      </li>
      <li class="header__navigation-item">
        <a href="https://ntz.digital/" class="header__navigation-link">Користувачам</a>
      </li>
      <li class="header__navigation-item">
        <a href="#" class="header__navigation-link header__navigation-link--btn">Особистий кабінет</a>
      </li>
    </ul>

    <button
      @click="toggleActive"
      class="header__menu-btn"
      :class="isActiveMenu ? 'header__menu-btn--active' : ''"
    >
      <span class="header__btn-item"/>
      <span class="header__btn-item"/>
      <span class="header__btn-item"/>
    </button>
  </nav>

  <nav v-else class="header__navigation">
    <div class="header__logo-wrapper">
      <a href="/" class="header__logo-link">
        <img src="../assets/logo.png" alt="NTZ" class="header__logo">
      </a>
    </div>

    <ul
      class="header__navigation-list"
      :class="isActiveMenu ? 'header__navigation-list--active' : ''"
    >
      <li class="header__navigation-item">
        <router-link class="header__navigation-link" :to="{ hash: '#userAdvantages', path: '/' }">Переваги</router-link>
      </li>
      <li class="header__navigation-item">
        <router-link class="header__navigation-link" :to="{ hash: '#howIt', path: '/' }">Як це працює</router-link>
      </li>
      <li class="header__navigation-item">
        <router-link class="header__navigation-link" :to="{ hash: '#facts', path: '/' }">Факти</router-link>
      </li>
      <li class="header__navigation-item">
        <router-link class="header__navigation-link" :to="{ hash: '#questions', path: '/' }">Питання та відповіді</router-link>
      </li>
      <li class="header__navigation-item">
        <router-link class="header__navigation-link" :to="{ hash: '#contacts', }">Контакти</router-link>
      </li>
      <li class="header__navigation-item">
        <a
          href="https://biz.ntz.digital/"
          class="header__navigation-link"
        >
          Для бізнесу
        </a>
      </li>
      <li class="header__navigation-item">
        <a
          href="#"
          class="header__navigation-link header__navigation-link--btn"
        >
          Кабінет тестування
        </a>
      </li>
    </ul>

    <button
      @click="toggleActive"
      class="header__menu-btn"
      :class="isActiveMenu ? 'header__menu-btn--active' : ''"
    >
      <span class="header__btn-item"/>
      <span class="header__btn-item"/>
      <span class="header__btn-item"/>
    </button>
  </nav>
</template>

<script>
export default {
  name: 'Navigation',
  data: () => ({
    isActiveMenu: false,
    url: document.location.href,
    getHomeUrl: process.env.VUE_APP_HOME_URL,
    getHomeUrlBiz: process.env.VUE_APP_HOME_URL_BIZ,
  }),
  watch: {
    $route () {
      if (this.isActiveMenu) {
        this.isActiveMenu = false
        document.querySelector('body').style.overflow = 'auto'
      }
    }
  },
  methods: {
    toggleActive () {
      this.isActiveMenu = !this.isActiveMenu

      document.querySelector('body').style.overflow = this.isActiveMenu ? 'hidden' : 'auto'
    }
  },
  computed: {
    isBizDomain () {
      return !!this.url.match(/biz/)
    }
  }
}
</script>