<template>
  <section class="how-it" id="howIt">
    <div class="container">
      <h4 class="how-it__heading">Як це працює</h4>

      <ul
        v-if="bullets === 'circle'"
        class="how-it__list"
      >
        <li
          v-for="(item, index) in howItData"
          :key="index"
          class="how-it__item"
          :class="item.isOnRight ? 'how-it__item--right' : 'how-it__item--left'"
        >
          <div
            class="how-it__item-wrapper"
            :class="item.isOnRight ? 'how-it__item-wrapper--text-left' : 'how-it__item-wrapper--text-right'"
          >
            <h5 class="how-it__item-heading">{{ item.heading }}</h5>
            <p class="how-it__item-text" v-html="item.text" />
          </div>
        </li>
      </ul>

      <ul
        v-else
        class="how-it__list"
      >
        <li
          v-for="(item, index) in howItData"
          :key="index"
          class="how-it__item how-it__item--numeric"
          :class="item.isOnRight ? 'how-it__item--right' : 'how-it__item--left'"
        >
          <div
            class="how-it__item-wrapper"
            :class="item.isOnRight ? 'how-it__item-wrapper--text-left' : 'how-it__item-wrapper--text-right'"
          >
            <h5 class="how-it__item-heading">{{ item.heading }}</h5>
            <p class="how-it__item-text" v-html="item.text" />

            <span
              class="how-it__bullet-numeric"
              :class="item.isOnRight ? 'how-it__bullet-numeric--left' : 'how-it__bullet-numeric--right'"
            >
              {{ ++index }}
            </span>
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: 'HowIt',
  props: {
    howItData: {
      type: Array,
      default: () => ([])
    },
    bullets: {
      type: String,
      default: 'circle'
    },
  }
}
</script>