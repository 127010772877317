<template>
  <section class="advantages" id="advantages">
    <div class="container">
      <h4 class="advantages__title">Переваги digital</h4>

      <ul class="advantages__list">
        <li class="advantages__item" v-for="(item, index) in list" :key="index">
          <img class="advantages__item-img" :src="item.img" :alt="item.title">
          <div class="advantages__item-text-wrapper">
            <h5 class="advantages__item-text-heading">{{ item.title }}</h5>
            <p class="advantages__item-text">{{ item.text }}</p>
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Advantages',
  data: () => ({
    list: [
      {
        img: require("@/assets/advantages-01.svg"),
        title: "Зручність використання",
        text: "Ми зробили функціонал практичним та доступним навіть для звичайного ока користувача. Спробуйте та переконайтесь самі."
      },
      {
        img: require("@/assets/advantages-02.svg"),
        title: "Економія часу",
        text: "Сучасний світ потребує впровадження новітніх технологій для економії ресурсів компаній." +
            " Наша команда сформувала усі необхідні процеси для вашої ефективної роботи."
      },
      {
        img: require("@/assets/advantages-03.svg"),
        title: "Повноцінна робота",
        text: "Усі етапи обробки заяк та роботи з сертифікатами тепер доступні у необмеженому об'ємі. Жодних стримувань."
      }
    ]
  })
}
</script>