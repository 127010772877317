<template>
  <section class="try-now" id="tryNow">
    <div class="container">
      <h4 class="try-now__heading">Спробуйте NTZ digital вже зараз і витрачайте менше часу</h4>
      <span class="try-now__subtitle">Наш продукт дає змогу працювати швидко та ефективно.</span>

      <FeedbackForm btnText="Розпочати" class="try-now__form" />

      <p class="try-now__info-text">
        Вартість здачі одного екзамену з курсу <a class="try-now__info-link" href="https://ntz.digital/">
        ntz.digital</a> складає від 300 грн за
        один курс.
        Відповідно до кількості курсів, що плануються до сдачі, ціна кожного курсу не змінюється
      </p>
    </div>
  </section>
</template>

<script>
import FeedbackForm from '@/components/FeedbackForm'
export default {
  name: 'TryNow',
  components: {FeedbackForm}
}
</script>