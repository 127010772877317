<template>
  <footer class="footer">
    <div class="container footer__container">
      <div class="footer__content">
        <div class="footer__logo-wrapper">
          <img src="../../assets/footer-logo.png" alt="NTZ Digital" class="footer__logo">

          <p class="footer__copyright">© 2021 NTZ digital</p>
        </div>

        <div class="footer__contacts" id="contacts">
          <h6 class="footer__title">КОНТАКТИ</h6>

          <a class="footer__contacts-link" href="mailto:info@ntz.digital">info@ntz.digital</a>
          <a class="footer__contacts-link" href="tel:+380443347313">+38 (044) 334 73 13</a>
        </div>

        <nav class="footer__navigation">
          <h6 class="footer__title">ДОКУМЕНТИ</h6>

          <ul class="footer__navigation-list">
            <li class="footer__navigation-item">
              <router-link class="footer__contacts-link" :to="{ name: 'privacy-policy' }">Політика
                конфіденційності</router-link>
            </li>
            <li class="footer__navigation-item">
              <router-link class="footer__contacts-link" :to="{ name: 'public-contract' }">Публічний договір</router-link>
            </li>
            <li class="footer__navigation-item">
              <router-link class="footer__contacts-link" :to="{ name: 'refund-policy' }">Політика повернення коштів</router-link>
            </li>
          </ul>
        </nav>

        <div class="footer__info">
          <ul class="footer__supports-icons-list">
            <li class="footer__supports-icons-item">
              <img src="@/assets/SupportPayIcons/visa.svg" width="40" height="30" alt="Visa">
            </li>
            <li class="footer__supports-icons-item">
              <img src="@/assets/SupportPayIcons/mastercard.svg" width="40" height="30" alt="Mastercard">
            </li>
            <li class="footer__supports-icons-item">
              <img src="@/assets/SupportPayIcons/apple-pay.svg" width="40" height="24" alt="Apple Pay">
            </li>
            <li class="footer__supports-icons-item">
              <img src="@/assets/SupportPayIcons/apple-pay.svg" width="40" height="24" alt="Google pay">
            </li>
            <li class="footer__supports-icons-item">
              <img src="@/assets/SupportPayIcons/privat-bank.svg" width="65" height="22" alt="Privat Bank">
            </li>
            <li class="footer__supports-icons-item">
              <img src="@/assets/SupportPayIcons/platon.png" width="74" height="20" alt="Platon">
            </li>
            <li class="footer__supports-icons-item">
              <img src="@/assets/SupportPayIcons/pci-dss.png" width="52" height="20" alt="PCI DSS">
            </li>
          </ul>
          <p class="footer__legal-address">
            Юридична адреса: Украина, 54000, Миколаївська
            обл., місто Миколаїв, вул.Чкалова 37/1
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
}
</script>